.skeleton {
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, transparent 0, #ffffff 50%, transparent 100%);
    background-size: 25% 50vh;
    background-position: -25% 40vh;
    animation: loading-skeleton 1s infinite;
}

@keyframes loading-skeleton {
    to {
        background-position: 125% 40vh;
    }
}
  
.dark .skeleton {
    background-image: linear-gradient(90deg, transparent 0, #18181b 50%, transparent 100%);
}