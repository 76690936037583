/* CSS for react-toggle */
.react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #0000001a;
}

.dark .react-toggle-track {
    background-color: #ffffff1a;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track {
    background-color: #00ADB5;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
    background: #ffffff;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
}

/* Swiper */
.swiper-wrapper {
    will-change: transform;
}

.swiper-pagination-bullet {
    background-color: #0e0e10 !important;
}

.swiper-pagination-bullet-active {
    background-color: #ffffff !important;
}

/* RHAP audio player */
.rhap_container {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.rhap_current-time,
.rhap_total-time {
    font-size: smaller !important;
    color: #53535f !important;
}

.dark .rhap_current-time,
.rhap_total-time {
    color: #adadb8 !important;
}

.rhap_progress-indicator,
.rhap_progress-filled {
    background-color: #00ADB5 !important;
    transition: left .150s ease-out;
}

.rhap_controls-section {
    margin-top: 1rem !important;
}

.rhap_main-controls-button {
    margin: 0 1rem !important;
    cursor: none !important;
    height: 4rem !important;
    width: 4rem !important;
    color: #00ADB5 !important;
}

.rhap_progress-container {
    cursor: none !important;
}

.rhap_button-clear:active {
    transform: scale(0.9);
}

.rhap_main-controls-button svg {
    font-size: 2rem !important;
}

.rhap_play-pause-button svg {
    font-size: 2rem !important;
}

.rhap_main-controls-button svg:focus {
    outline: none !important;
}