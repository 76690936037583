.quiz-audio-bars {
    position: relative;
    height: 1rem;
    width: 27px;
}

.quiz-audio-bars .bar {
    display: inline-block;
    bottom: 0;
    position: absolute;
    height: 3px;
    width: 3px;
    animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
    0% {
        opacity: .3;
        height: 3px; 
    }
    100% {
        opacity: 1;       
        height: 1rem;        
    }
}

.bar:nth-child(1)  { left: 1px; animation-duration: 400ms; }
.bar:nth-child(2)  { left: 8px; animation-duration: 300ms; }
.bar:nth-child(3)  { left: 16px; animation-duration: 350ms; }
.bar:nth-child(4)  { left: 24px; animation-duration: 450ms; }

@keyframes pulse-animation-correct {
    0% { background-color: #00ADB5; }
    100% { background-color: #7AC74F; }
}