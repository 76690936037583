@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Baloo Chettan 2';
  src: url(assets/font/BalooChettan2.ttf);
  font-display: swap;
}

body {
  font-family: 'Baloo Chettan 2', sans-serif, cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}